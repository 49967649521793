import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import Inscription from '../../components/Newsletter/Inscription'
//import BlogCard from '../components/BlogContent/BlogCard'
import StartProject from '../../components/Common/StartProject'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import {Link} from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router';

import imgLivreBlancCompl from '../../../src/assets/images/livre_blanc_compliance.png'
import imgLivreBlancFSC from '../../../src/assets/images/livret-fsc.png'
import imgLivreBlancRemontees from '../../../src/assets/images/livret-remontees.png'


const kebabCase = string => string
    .normalize("NFD").replace(/\p{Diacritic}/gu, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

const normalCase = chaine => {
    const mots = chaine.split("-")
    //console.log(mots)
    for (let i = 0; i < mots.length; i++) {
        mots[i] = mots[i][0].toUpperCase() + mots[i].substr(1)
    }
    const res = mots.join(" ")
    
    return res
}



const Blog = ({ data }) => {
    console.log(data)
    const location = useLocation()
    const chemin= location.pathname.split('/')
    const laCat=(chemin[chemin.length-1] === "") ? chemin[chemin.length-2]:chemin[chemin.length-1]
    
    return (
        <Layout>
            <Helmet>
                <title>Blog Seekreet | Infos et actus data management</title>
                <meta name="description" content="Découvrez nos articles et actualités sur les thématiques compliance, QHSE - qualité, Food Safety Culture (FSC), certification, partage d’informations, digitalisation des process..." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle={"Blog - Catégorie : "+normalCase(laCat)}
                homePageText="Accueil" 
                homePageUrl="/" 
                parentActive="Blog"
                parentPageUrl="/blog"
                activePageText={normalCase(laCat)} 
            />
            <div className="blog-area ptb-100 bg-e3fbff">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className='row'>
                                {
                                    data.cat.nodes.map(node => (
                                        <div className="col-lg-6 col-md-6">
                                            <div className="single-blog-post">
                                                <div className="post-image" key={node.id}>
                                                    <Link to={"/"+node.frontmatter.slug}>
                                                        <GatsbyImage
                                                            image={getImage(node.frontmatter.hero_image)}
                                                            alt={node.frontmatter.hero_image_alt}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="post-content">
                                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                                        <li className={"default "+kebabCase(node.frontmatter.category)}>
                                                            <Link to={"/cat/"+kebabCase(node.frontmatter.category)}>
                                                                {node.frontmatter.category}
                                                            </Link>
                                                        </li>  
                                                        <li>
                                                            <i className='bx bx-calendar'></i> {node.frontmatter.date}
                                                        </li>
                                                    </ul>
                                                    <h3>
                                                        <Link to={"/"+node.frontmatter.slug}>
                                                            {node.frontmatter.title}
                                                        </Link>
                                                    </h3>
                                                    <p className='time-to-read'>{"Temps de lecture : "+node.frontmatter.readTime}</p>
                                                    <p>{node.frontmatter.teaser}</p>
                                                    <div className='follow-link'>
                                                        <Link to={"/"+node.frontmatter.slug}>
                                                            <i className="bx bx-right-arrow-alt"></i> Lire la suite <span></span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="widget-area">
                                <div className="widget widget_tracer_posts_thumb">
                                    <h3 className="widget-title">Nos derniers articles</h3>
                                    {data.lastThree.nodes.map(node => (
                                        <article className="item" key={node.id}>
                                            <Link to={"/"+node.frontmatter.slug} className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <GatsbyImage
                                                        image={getImage(node.frontmatter.hero_image)}
                                                        alt={node.frontmatter.hero_image_alt}
                                                        className='vignette-article'
                                                    />
                                                </span>

                                            </Link>
                                            <div className="info">
                                                <span>{node.frontmatter.date}</span>
                                                <h4 className="title usmall">
                                                    <Link to={"/"+node.frontmatter.slug}>
                                                        {node.frontmatter.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                    ))}
                                </div>
                                {/** Affichage des catégories */}
                                <div className="widget widget_categories">
                                    <h3 className="widget-title">Catégories</h3>
                                    <div className="tagcloud-custom">
                                        {data.cats.group.map(cat => (
                                            <Link to={"/cat/"+kebabCase(cat.category)} className={"default "+kebabCase(cat.category)} key={cat.category}>
                                                {cat.category} <span className="tag-link-count">{"("+cat.totalCount+")"}</span>
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="tagcloud-all center">
                                        <Link to="/blog">
                                        Tous les articles
                                        </Link>
                                    </div>
                                </div>
                                {/** Affichage des livres blancs */}
                                <div className="widget-area">
                                    <div className="widget widget_tracer_posts_thumb">
                                        <h3 className="widget-title">Nos livres blancs</h3>
                                        <article className="item" >
                                            <Link to="/livre-blanc-compliance" className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <img src={imgLivreBlancCompl} alt="livre blanc compliance" />
                                                </span>
                                            </Link>
                                            <div className="info">
                                                <span>Compliance</span>
                                                <h4 className="title usmall">
                                                    <Link to="/livre-blanc-compliance">
                                                        Comment intégrer un dispositif de signalement dans votre programme compliance ?
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                        <article className="item" >
                                            <Link to="/livre-blanc-fsc" className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <img src={imgLivreBlancFSC} alt="livre blanc FSC" />
                                                </span>
                                            </Link>
                                            <div className="info">
                                                <span>Food Safety Culture</span>
                                                <h4 className="title usmall">
                                                    <Link to="/livre-blanc-fsc">
                                                        Les points clés pour la mise en place d’une culture qualité et sécurité des aliments
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                        <article className="item">
                                            <Link to="/livre-blanc-remontees" className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <img src={imgLivreBlancRemontees} alt="livre blanc Remontées d'information" />
                                                </span>
                                            </Link>
                                            <div className="info">
                                                <span>Guides</span>
                                                <h4 className="title usmall">
                                                    <Link to="/livre-blanc-remontees">
                                                        Comment fluidifier et sécuriser vos remontées d'information grâce au digital ?
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-12 col-md-12">
                <div className="pagination-area text-center">
                    <Link to="#" className="prev page-numbers">
                        <i className='bx bx-chevrons-left'></i>
                    </Link>
                    <span className="page-numbers current" aria-current="page">1</span>
                    <Link to="#" className="page-numbers">
                        2
                    </Link>
                    <Link to="#" className="page-numbers">
                        3
                    </Link>
                    <Link to="#" className="page-numbers">
                        4
                    </Link>
                    <Link to="#" className="next page-numbers">
                        <i className='bx bx-chevrons-right'></i>
                    </Link>
                </div>
            </div>
            <br /> */}
            <Inscription />
            <StartProject />
            <Footer />
        </Layout>
    );
}

export const query = graphql`
query($frontmatter__category: String!) {
    cat: allMdx(filter: {frontmatter: {category: {eq: $frontmatter__category}}}, sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          id
          frontmatter {
            metaTitle
            metaDescription
            teaser
            readTime
            slug
            date(formatString: "MMMM YYYY", locale: "fr")
            author
            category
            title
            hero_image {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
          }
        }
    }
    lastThree: allMdx (limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          id
          frontmatter {
            slug
            date(formatString: "MMM YYYY", locale: "fr")
            title
            hero_image {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
        }
    }
    cats: allMdx{
        group(field: frontmatter___category) {
			category: fieldValue
            totalCount
        }
    }
}   
`


export default Blog;